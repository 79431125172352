import React, { Component } from 'react';

import Header from '../components/HeaderSection/Header';
import HeroSection from '../components/HeroSection/HeroSix';
import FeatureSection from '../components/Features/FeatureSix';
import ServiceSection from '../components/ServiceSection/ServiceSix';
import DiscoverSection from '../components/DiscoverSection/DiscoverSix';
import BrandingSection from "../components/BrandingSection/Branding";
import Work from '../components/WorkSection/Work';
import ScreenshotSection from '../components/ScreenshotSection/ScreenshotsOne';
import ReviewSection from '../components/ReviewSection/ReviewOne';
import PricingSection from '../components/PricingSection/PricingOne';
import FaqSection from '../components/FaqSection/FaqOne';
import Download from '../components/DownloadSection/Download';
import Subscribe from '../components/SubscribeSection/Subscribe';
import Team from '../components/TeamSection/Team';
import ContactSection from '../components/ContactSection/Contact';
import FooterSection from '../components/FooterSection/Footer';

class ThemeSix extends Component {
  state = {
    isSearchOpen: false,
    displayed_list: [],
    search_input: ''
  }

  componentDidMount() {
    this.Types_List()
    // this.Jobs_List()
  }

  Open_Search_Container = () => {
    this.setState({isSearchOpen: true})
  }

  Close_Search_Container = () => {
    document.querySelector('body').style.overflow = 'auto'
    this.setState({isSearchOpen: false})
  }

  Types_List = async () => {
    let fet = await fetch('https://yassbk.com/landing/types_list')
    let rep = await fet.json()
    let displayed_list = []
    for (let i = 0; i < rep.length; i++) {
      displayed_list.push({
        name: rep[i].name_type,
        title: rep[i].title_type
      })
    }
    this.setState({displayed_list})
    console.log(rep)
  }

  Jobs_List = async () => {
    let fet = await fetch('https://yassbk.com/landing/jobs_list')
    let rep = await fet.json()
    console.log(rep)
  }


  Jobs_Search = async (text) => {
    this.setState({search_input: text})
    let fet = await fetch('https://yassbk.com/landing/jobs_search', {
      method: "POST",
      body: JSON.stringify({
        text: text
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    let displayed_list = []
    for (let i = 0; i < rep.length; i++) {
      displayed_list.push({
        name: rep[i].name_job,
        title: rep[i].title_job
      })
    }
    this.setState({displayed_list})
    if (text == '') this.Types_List()
  }

  render() {
    return (
      !this.state.isSearchOpen
      ?
      <div className="homepage-6">
        {/*====== Scroll To Top Area Start ======*/}
        {/* <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div> */}
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-white.png"} />
          <HeroSection Open_Search_Container={this.Open_Search_Container} />
          <ServiceSection />
          <DiscoverSection />
          <BrandingSection />
          <Work />
          <ReviewSection />
          <FeatureSection />
          <ScreenshotSection />
          <PricingSection />
          <FaqSection />
          <Team />
          <Download />
          <Subscribe />
          <ContactSection />
          <FooterSection />
        </div>
      </div>
      :
      <div className='search_container'>
        <button className="btn btn-bordered" id='search_container_close' onClick={this.Close_Search_Container}>Fermer</button>
        <div className='container position-relative' style={{paddingTop: 10, paddingBottom: 10}}>
          <div className="subscribe-form d-flex align-items-center" onClick={this.SearchClick}>
            <input type="email" className="form-control" autoFocus placeholder="Quel est votre secteur d'activité ?" onChange={(e) => this.Jobs_Search(e.target.value)} />
            <button type="submit" className="btn btn-bordered" style={{ position: 'absolute', right: 0 }}>
              <i className="fas fa-search" id='fa-test'></i>
            </button>
          </div>
        {
          this.state.search_input == ''
          ?
          <>
          <h2>En vedette</h2>
          <div className='search_main'>
          {
            this.state.displayed_list.map((item, index) => (
              <div 
                className='search_case' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
                onClick={() => {
                  document.querySelector('body').style.overflow = 'auto'
                  window.location = `#/theme-six/${item.name}`
                }}
              >
                <span>Type</span>
                <span style={{fontSize: 20, fontWeight: 500}}>{item.title}</span>
              </div>
            ))
          }
          <div className='search_case'></div>
          </div>
          </>
          :
          <>
          <h2></h2>
          <div className='search_main'>
          {
            this.state.displayed_list.map((item, index) => (
              <div 
                className='search_case' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
                onClick={() => {
                  document.querySelector('body').style.overflow = 'auto'
                  window.location = `#/theme-six/${item.name}`
                }}
              >
                <span style={{fontSize: 20, fontWeight: 500}}>{item.title}</span>
              </div>
            ))
          }
          {this.state.displayed_list.length == 1 ? undefined : <div className='search_case'></div>}
          </div>
          </>
        }
        </div>
      </div>
    );
  }
}

export default ThemeSix;